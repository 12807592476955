@import "../../../jun-styling";

.answer-view {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-bottom: 0.5em;
}

.answer-view-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.answer-view-heading {
  display: inline-block;
}

.answer-view-used-prompt {
  //padding-left: 60px;
}

.answer-view-used-prompt-component {
  background-color: $junHighlightBackgroundColor;
  border-radius: $defaultBorderRadius;
}

.answer-view-notes {
  background-color: $junHighlightBackgroundColor;
  border-radius: $defaultBorderRadius;
}

.answer-view-notes.collapsed {
  transform: scale(1, 0);
  height: 0;
}

.answer-view-notes.expanded {
  transform: scale(1);
  padding: 12px;
}

.answer-view-timestamp {
  align-self: flex-start;
}

.answer-view-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.answer-view-add-rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1ex;
}